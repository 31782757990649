export const APP_VERSION = 'v0.3.1-alpha'
export const LAST_UPDATED = '20 August 2022'
export const FIRST_RELEASE = '23 March 2021'
export const PROJECT_SIZE = '19,2 Mb (20 022 944 b)'
export const COUNT_OF_VISUAL_TESTS = 126
export const COUNT_OF_UNIT_TESTS = 0
export const COUNT_OF_VISUALIZATION = 1

export const libVersions = {
  react: '17.0.1',
  'react-dom': '17.0.1',
  gatsby: '3.0.1',
  threejs: '0.126.1',
  '@react-three/fiber': '6.0.14',
  '@react-three/drey': '4.1.8',
  'react-helmet': '6.1.0',
  'use-query-params': '1.2.2',
  typescript: '4.2.2',
  'react-spring': '9.5.2',
}
