import * as React from 'react'

import { Layout, SEO } from '../modules'
import { Typography } from '../fragments'
import { siteInfo } from '../lib/seo'
import { Flex } from '../components'

import {
  APP_VERSION,
  COUNT_OF_UNIT_TESTS,
  COUNT_OF_VISUAL_TESTS,
  COUNT_OF_VISUALIZATION,
  LAST_UPDATED,
  libVersions,
  FIRST_RELEASE,
  PROJECT_SIZE,
} from '../app.config'

import '../styles/info.scss'

const IndexPage: React.FC = (): JSX.Element => {
  return (
    <>
      <SEO lang="en" {...siteInfo} />
      <Layout>
        <main className="info">
          <Flex align="flex-start">
            <Flex.Column>
              <Flex.Row justify="flex-start">
                <Flex.Column className="info__label">
                  <Typography>App version:</Typography>
                </Flex.Column>
                <Flex.Column>
                  <Typography>{APP_VERSION}</Typography>
                </Flex.Column>
              </Flex.Row>
              <Flex.Row justify="flex-start">
                <Flex.Column className="info__label">
                  <Typography>Last updated:</Typography>
                </Flex.Column>
                <Flex.Column>
                  <Typography>{LAST_UPDATED}</Typography>
                </Flex.Column>
              </Flex.Row>
              <Flex.Row justify="flex-start">
                <Flex.Column className="info__label">
                  <Typography>First release:</Typography>
                </Flex.Column>
                <Flex.Column>
                  <Typography>{FIRST_RELEASE}</Typography>
                </Flex.Column>
              </Flex.Row>
              <Flex.Row justify="flex-start">
                <Flex.Column className="info__label">
                  <Typography>Count of visualizations:</Typography>
                </Flex.Column>
                <Flex.Column>
                  <Typography>{COUNT_OF_VISUALIZATION}</Typography>
                </Flex.Column>
              </Flex.Row>
              <Flex.Row justify="flex-start">
                <Flex.Column className="info__label">
                  <Typography>Count of visual tests:</Typography>
                </Flex.Column>
                <Flex.Column>
                  <Typography>{COUNT_OF_VISUAL_TESTS}</Typography>
                </Flex.Column>
              </Flex.Row>
              <Flex.Row justify="flex-start">
                <Flex.Column className="info__label">
                  <Typography>Count of unit tests:</Typography>
                </Flex.Column>
                <Flex.Column>
                  <Typography>{COUNT_OF_UNIT_TESTS}</Typography>
                </Flex.Column>
              </Flex.Row>
              <Flex.Row justify="flex-start">
                <Flex.Column className="info__label">
                  <Typography>Project size:</Typography>
                </Flex.Column>
                <Flex.Column>
                  <Typography>{PROJECT_SIZE}</Typography>
                </Flex.Column>
              </Flex.Row>
              <Flex.Row justify="flex-start">
                <Flex.Column className="info__label">
                  <Typography className="info__title">
                    Libraries versions
                  </Typography>
                </Flex.Column>
              </Flex.Row>
              {Object.keys(libVersions).map(key => (
                <Flex.Row justify="flex-start">
                  <Flex.Column className="info__label">
                    <Typography>{key}</Typography>
                  </Flex.Column>
                  <Flex.Column>
                    {/* @ts-ignore */}
                    <Typography>{libVersions[key]}</Typography>
                  </Flex.Column>
                </Flex.Row>
              ))}
            </Flex.Column>
          </Flex>
        </main>
      </Layout>
    </>
  )
}

export default IndexPage
